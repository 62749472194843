<template>
  <b-sidebar id="sidebar-invoice-add-payment" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Payment
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <validation-observer ref="paymentRules">
        <b-form class="p-2" @submit.prevent>
          <!-- Invoice Balance -->
          <b-form-group label="Invoice Balance" label-for="invoice-balance">
            <cleave style="height:2.142rem" v-model="invoiceData.leftTotal" disabled class="form-control" :raw="true" :options="options.number" />
          </b-form-group>

          <!-- Payment Amount -->
          <b-form-group label="Payment Amount" label-for="payment-amount">
            <validation-provider name="Payment Amount" #default="{ errors }" rules="required">
              <cleave style="height:2.142rem" @input="valueControl(addPaymentData.paymentAmount)" v-model="addPaymentData.paymentAmount" class="form-control" :raw="true" :options="options.number" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Payment Date -->
          <b-form-group label="Payment Date" label-for="payment-date">
            <validation-provider name="Payment Date" #default="{ errors }" rules="required">
              <flat-pickr v-model="addPaymentData.paymentDate" :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Payment Method" label-for="payment-method">
            <validation-provider name="Payment Method" #default="{ errors }" rules="required">
              <v-select v-model="addPaymentData.paymentMethod" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="paymentMethods" label="Payment Method" placeholder="Select Payment Method" input-id="payment-method" :clearable="false" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Internal Payment Note -->
          <b-form-group label="Internal Payment Note" label-for="internal-payment-note">
            <b-form-textarea id="internal-payment-note" v-model="addPaymentData.note" placeholder="Internal Payment Note" rows="5" trim />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" @click="save">
              Send
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

export default {
  components: {
    ToastificationContent,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    Cleave,
    ValidationProvider,
    ValidationObserver,

    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    invoiceData: {
      type: Object,
      required: false,
    },
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      required,
      email,
      addPaymentData: {
        invoiceBalance: null,
        paymentAmount: null,
        paymentDate: null,
        paymentMethod: null,
        note: null,
        vendorReference: null,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      tempStatus: 'C',
      tempInvoiceData: null,

      paymentMethods: ['Cash', 'Bank Transfer', 'Debit Card', 'Credit Card', 'Paypal'],
    };
  },

  methods: {
    save() {
      this.$refs.paymentRules.validate().then((success) => {
        if (success) {
          this.addPaymentData.status = 'C';
          this.addPaymentData.processId = this.invoiceData.id;
          this.addPaymentData.invoiceBalance = this.invoiceData.leftTotal;

          this.tempInvoiceData = this.invoiceData;

          store
            .dispatch('accounting/savePayment', this.addPaymentData)
            .then((response) => {
              this.tempInvoiceData.payments.push(response.data);

              this.addPaymentData = {
                invoiceBalance: null,
                paymentAmount: null,
                paymentDate: null,
                paymentMethod: null,
                note: null,
                vendorReference: null,
              };

              this.invoiceData.leftTotal = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Payment Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              console.log(error);
              this.formShow = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  text: 'Please try again or report an issue to support',
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              });
              // router.push({ name: 'quote-list' });
            });

          if (this.invoiceData.leftTotal - this.addPaymentData.paymentAmount > 0) {
            this.tempStatus = 'D';
          }
          else if(this.invoiceData.leftTotal-this.addPaymentData.paymentAmount == 0){
            this.tempStatus = 'C';
          }

          store
            .dispatch('accounting/adjustmentStatus', { id: router.currentRoute.params.id, status: this.tempStatus })
            .then((response) => {
              this.invoiceData.status = response.data.status;
            })
            .catch((error) => {
              console.log(error);
              this.formShow = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  text: 'Please try again or report an issue to support',
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              });
              // router.push({ name: 'quote-list' });
            });

          this.$emit('update:invoiceData.payments', this.tempInvoiceData.payments);

          this.$root.$emit('bv::toggle::collapse', 'sidebar-invoice-add-payment');
        }
      });
    },

    valueControl(val) {
      if (Number(val) > Number(this.invoiceData.leftTotal)) {
        this.addPaymentData.paymentAmount = parseFloat(this.invoiceData.leftTotal);
      }
    },
  },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  created() {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
